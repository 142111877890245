import {withRouter} from "react-router-dom"
import "./header.scss"
import logo from "../../../img/logo-light.svg"
import logoNight from "../../../img/logo-night.svg"
import {useContext} from "react";
import {GlobalContext} from "../../pages/App";
import nightMode from "../../../img/icons/nightMode.svg"
import lightMode from "../../../img/icons/lightMode.svg"
import Navigation from "./Navigation/Navigation";



function Header({updateLang}) {
   const {globalState: {language, theme}, setGlobalState} = useContext(GlobalContext)

   function switchThemeMode(value) {
      setGlobalState(prevState => ({...prevState, theme: value}))
   }

   return (
      <div className="header light-bg">
         <div className="top-block">
            <div className="container">
               <div className="logo-block">
                  <img className="logo" src={theme === "light" ? logo : logoNight} alt="logo"/>
                  <div className="applications-names">
                     <span className="main-text-color">DELIVERY</span>
                     <span className="main-text-color">SELLER</span>
                     <span className="main-text-color">ADMIN </span>
                  </div>
               </div>
               <div className="right-top-block">
                  <div className="languages-block">
                            <span
                               onClick={() => updateLang("ru")}
                               className={`languages-block-item ${(language === "ru" && "active") || ""}`}>RU
                            </span>
                     <span className="line"/>
                     <span
                        className={`languages-block-item ${(language === "ua" && "active") || ""}`}
                        onClick={() => updateLang("ua")}>UA
                            </span>
                     <span className="line"/>
                     <span
                        onClick={() => updateLang("en")}
                        className={`languages-block-item ${(language === "en" && "active") || ""}`}>EN
                            </span>
                  </div>
                  <div className="change-mode-button">
                     <img
                        onClick={() => switchThemeMode("night")}
                        className={theme === "light" ? "light show" : "light hide"}
                        src={nightMode}
                        alt="#"
                     />
                     <img
                        onClick={() => switchThemeMode("light")}
                        className={theme === "night" ? "night show" : "night hide"}
                        src={lightMode}
                        alt="#"
                     />
                  </div>
               </div>
            </div>
         </div>
         <Navigation/>
      </div>
   )
}

export default withRouter(Header)