import languagesData from "./languagesData";

export default function getText(path) {
    const language = window.localStorage.getItem("language")
    const pathArray = path.split(".")

    let text = null;
    for (let i = 0; i <= pathArray.length; i++) {
        if (i === 0) text = languagesData[pathArray[i]]
        else if (i === pathArray.length) text = text?.[language]
        else text = text?.[pathArray[i]]
    }

    return text || "invalid text path"
}