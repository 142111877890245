import "./portfolio-seller.scss"
import getText from "../../../../appText/getText";
import googlePlay from "../../../../img/GooglePlay.svg"
import appStore from "../../../../img/AppStore.svg"
import SliderForPortfolio from "../../../pageComponents/SliderForPortfolio/SliderForPortfolio";

const slides = [
    {
        img: <div className="phone">Seller 1</div>,
        text: <p className="description">Опис кожного екрану, <br/> яких визначимо пізніше 1</p>
    },
    {
        img: <div className="phone">Seller 2</div>,
        text: <p className="description">Опис кожного екрану, <br/> яких визначимо пізніше 2</p>
    },
    {
        img: <div className="phone">Seller 3</div>,
        text: <p className="description">Опис кожного екрану, <br/> яких визначимо пізніше 3</p>
    },
]

function PortfolioSeller() {

    return (
        <div className="seller">
            <h1 className="title">Kavapp Seller</h1>
            <h3 className="subtitle">{getText("programForSeller")}</h3>
            <p>{getText("pages.portfolio_seller.p")}</p>
            <div className="links_group">
                <a className="link_button" href="#">
                    <img src={googlePlay} alt="#"/>
                </a>
                <a className="link_button" href="#">
                    <img src={appStore} alt="#"/>
                </a>
            </div>
            <SliderForPortfolio slides={slides}/>
        </div>
    )
}

export default PortfolioSeller
