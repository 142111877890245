import "./portfolio-admin.scss"
import getText from "../../../../appText/getText";
import googlePlay from "../../../../img/GooglePlay.svg"
import appStore from "../../../../img/AppStore.svg"
import SliderForPortfolio from "../../../pageComponents/SliderForPortfolio/SliderForPortfolio";

const slides = [
    {
        img: <div className="phone">Admin 1</div>,
        text: <p className="description">
            <strong>Активні робочі зміни</strong> <br/><br/>
            Через додаток для логіста можна бачити всі відкриті робочі зміни, та чи не знаходяться вони на перерві.
            Також, саме тут логіст може зробити запит на інкасацію чи створити автозамовлення
        </p>
    },
    {
        img: <div className="phone">Admin 2</div>,
        text: <p className="description">
            <strong>Активні робочі зміни</strong> <br/><br/>
            Через додаток для логіста можна бачити всі відкриті робочі зміни, та чи не знаходяться вони на перерві.
            Також, саме тут логіст може зробити запит на інкасацію чи створити автозамовлення
        </p>
    },
    {
        img: <div className="phone">Admin 3</div>,
        text: <p className="description">
            <strong>Активні робочі зміни</strong> <br/><br/>
            Через додаток для логіста можна бачити всі відкриті робочі зміни, та чи не знаходяться вони на перерві.
            Також, саме тут логіст може зробити запит на інкасацію чи створити автозамовлення
        </p>
    },
]

function PortfolioAdmin() {

    return (
        <div className="admin">
            <h1 className="page-title">Kavapp Admin</h1>
            <h3 className="subtitle">{getText("programForAdmin")}</h3>
            <p className="category-description">{getText("pages.portfolio_admin.p")}</p>
            <div className="links_group">
                <a className="link_button" href="#">
                    <img src={googlePlay} alt="#"/>
                </a>
                <a className="link_button" href="#">
                    <img src={appStore} alt="#"/>
                </a>
            </div>
            <SliderForPortfolio slides={slides}/>
        </div>
    )
}

export default PortfolioAdmin
