import {useState} from "react";
import "./slider_for_portfolio.scss"

function SliderForPortfolio({slides}) {
    const [slideData, setSlideData] = useState(slides[0])
    const [count, setCount] = useState(0)

    function onPrevButtonClick() {
        if (count === 0) {
            setSlideData(slides[slides.length - 1])
            setCount(slides.length - 1)
            return
        }

        setSlideData(slides[count - 1])
        setCount(count - 1)
    }
    
    function onNextButtonClick() {
        if (count === slides.length -1) {
            setSlideData(slides[0])
            setCount(0)
            return
        }

        setSlideData(slides[count + 1])
        setCount(count + 1)
    }

    return (
        <div className="slider-for-portfolio">
            <button onClick={onPrevButtonClick} className="slider-button">&#10094;</button>
            <div className="slide">
                {slideData.img}
                {slideData.text}
            </div>
            <button onClick={onNextButtonClick} className="slider-button">&#10095;</button>
        </div>
    )
}

export default SliderForPortfolio