import "./footer.scss"
import getText from "../../../appText/getText";
import facebook from "../../../img/icons/facebook.svg"
import instagram from "../../../img/icons/instagram.svg"
import gmail from "../../../img/icons/gmail.svg"
import viber from "../../../img/icons/viber.svg"
import telegram from "../../../img/icons/telegram.svg"
import mastercardLight from "../../../img/icons/mastercard-light.svg"
import mastercardNight from "../../../img/icons/mastercard-night.svg"
import visa from "../../../img/icons/VisaBlue.svg"
import visaWhite from "../../../img/icons/VisaWhite.svg"
import { useContext } from "react";
import { GlobalContext } from "../../pages/App";

function Footer() {
    const { globalState: { theme } } = useContext(GlobalContext)

    return (
        <div className="footer light-gradient">
            <div className="container">
                <div className="social-networks">
                    <span className="title">{getText("footer.connect")}</span>
                    <div className="social-networks-list">
                        <a href="#"><img src={gmail} alt="#" /></a>
                        <a href="#"><img src={facebook} alt="#" /></a>
                        <a href="#"><img src={instagram} alt="#" /></a>
                        <a href="#"><img src={telegram} alt="#" /></a>
                        <a href="#"><img src={viber} alt="#" /></a>
                    </div>
                </div>
                <div className="phone-block">
                    <div className="title">{getText("footer.phone")}</div>
                    <span className="phone">+38(063)753-49-09</span>
                    <span className="work_schedule">(пн-пт 8:00-17:00)</span>
                </div>
                <div className="payment-block">
                    <div className="title">{getText("footer.we_accept")}</div>
                    <div className="payment-list">
                        <img src={theme === "light" ? visa : visaWhite} alt="#" />
                        <img src={theme === "light" ? mastercardLight : mastercardNight} alt="#" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer