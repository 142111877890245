import "./player.scss"
import {useEffect, useRef, useState} from "react";
import play from "./../../../img/icons/play.svg"
import Loader from "../Loading/Loader";

function Player({videosList}) {
   const [iframe, setIframe] = useState({
      src: null,
      iframe: null
   })
   const [loading, setLoading] = useState(true)
   const videoContainerRef = useRef()

   useEffect(() => {
      setIframe({
         src: videosList[0].src,
         iframe: renderIframe(videosList[0].src)
      })
   }, [])

   function renderIframe(src) {
      return (
         <iframe
            onLoad={() => setLoading(false)}
            width="560"
            height="315"
            src={src}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
         />
      )
   }

   function renderPreviewVideos(videosList) {
      if (!videosList || !Array.isArray(videosList) || !videosList.length) return null

      return videosList.map((video, index) => {
         const onClick = () => {
            if (iframe.src !== video.src) {
               setLoading(true)
               setIframe({
                  src: video.src,
                  iframe: renderIframe(video.src)
               })
               videoContainerRef.current.scrollIntoView()
            }
         }
         return (
            <div key={index} className="video-preview-container"  onClick={onClick}>
               <div className="video-preview-wrapper">
                  <div className="video-preview">
                     <span className="preview-title">{video.title}</span>
                     <img className="preview-play" src={play} alt="#"/>
                  </div>
               </div>
            </div>
         )
      })
   }

   return (
      <div className="player" ref={videoContainerRef}>
         <div className="video-container">
            <div className="video">
               {iframe.iframe}
               {loading && <div className="loading-video">
                  <Loader/>
               </div>}
            </div>
         </div>
         <div className="video-previews">
            {renderPreviewVideos(videosList)}
         </div>
      </div>
   )
}

export default Player