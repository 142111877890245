import Slider from "../../pageComponents/Slider/Slider";
import "./portfolio.scss"
import getText from "../../../appText/getText";
import {useHistory} from "react-router-dom";

function Portfolio() {
    const history = useHistory()

    function onPortfolioItemClick(path) {
        history.push("/portfolio/" + path)
    }

    function getSlides() {
        const slides = []
        for (let i = 0; i < 3; i++) {
            slides.push(<div className="slide"><h3>{ i + 1 }</h3></div>)
        }
        return slides
    }

    return (
        <div className="portfolio">

            <Slider slides={getSlides()}/>

            <h2>{getText("portfolio")}</h2>

            <div className="portfolio-items">
                <div className="portfolio-item">
                    <img src="https://fakeimg.pl/350x200/?text=problem?" alt="#"/>
                    <div className="top-layout light-gradient" onClick={() => onPortfolioItemClick("admin")}>
                        <span className="portfolio-item-title">Kavapp<br/>Admin</span>
                        <p>Програма для логістів</p>
                    </div>
                </div>
                <div className="portfolio-item">
                    <img src="https://fakeimg.pl/350x200/?text=problem?" alt="#"/>
                    <div className="top-layout light-gradient" onClick={() => onPortfolioItemClick("sale")}>
                        <span className="portfolio-item-title">Kavapp<br/>Seller</span>
                        <p>Програма для логістів</p>
                    </div>
                </div>
                <div className="portfolio-item">
                    <img src="https://fakeimg.pl/350x200/?text=problem?" alt="#"/>
                    <div className="top-layout light-gradient" onClick={() => onPortfolioItemClick("delivery")}>
                        <span className="portfolio-item-title">Kavapp<br/>Delivery</span>
                        <p>Програма для логістів</p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Portfolio