import SlickSlider from "react-slick";
import "./Slider.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Slider({slides, className}) {
    const settings = {
        dots: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return(
       <div className="slider">
           <SlickSlider className={className} {...settings}>
               {slides.map(slide => {
                  return (
                     <div className="slide-wrapper">
                        <div className="slide-body">
                           {slide}
                        </div>
                     </div>
                  )
               })}
           </SlickSlider>
       </div>
    )
}

export default Slider