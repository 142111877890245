import { createContext, useEffect, useState } from 'react';
import './app.scss';
import Header from "../pageComponents/Header/Header"
import { Router } from 'react-router-dom';
import { createHashHistory } from "history"
import getRoutes from '../../routes/routes';
import {getLanguage, setLanguage} from "../../methods/otherMethods";
import Footer from "../pageComponents/Footer/Footer";

export const GlobalContext = createContext({})
const history = createHashHistory()

function App() {


    const [globalState, setGlobalState] = useState({
        language: "ua",
        theme: "light",
        windowIsSmall: window.innerWidth < 800
    })

    useEffect(() => {
        let lang = getLanguage()

        if (!lang && window.navigator) {
            lang = window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage
        }
        lang && updateLang(lang)

        const resizeListener = window.addEventListener("resize", (event) => {
            setGlobalState(prevState => ({...prevState, windowIsSmall: event.target.innerWidth < 800}))
        })
        return (() => window.removeEventListener("resize", resizeListener))
    }, [])

    function updateLang(value) {
        const newLanguage = value.slice(0, 2)
        setLanguage(newLanguage);
        setGlobalState({ ...globalState, language: newLanguage });
    }

    return (
        <GlobalContext.Provider value={{ globalState, setGlobalState }}>
            <Router history={history}>
                <div className={`app ${globalState.theme}`}>
                    <Header updateLang={updateLang} />
                    <div className="container content">
                        {getRoutes()}
                    </div>
                    <Footer />
                </div>
            </Router>
        </GlobalContext.Provider>
    );
}

export default App;
