import Slider from "../../pageComponents/Slider/Slider";
import "./tariffs.scss"
import getText from "../../../appText/getText";

function Tariffs() {
    function getSlides() {
        const slides = []
        for (let i = 0; i < 3; i++) {
            slides.push(<div className="slide"><h3>{i + 1}</h3></div>)
        }
        return slides
    }

    return (
        <div className="tariffs">
            <Slider slides={getSlides()}/>
            <h2>{getText("tariffs")}</h2>
            <div className="tariff-cards">
                <div className="tariff-card gradient">
                    <span className="title">START</span>
                    <span className="price">{getText("pages.tariffs.card_title.1")}</span>
                    <ul className="characteristics-list">
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.1")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.2")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.3")}</li>
                        <li className="characteristics-item false">{getText("pages.tariffs.card_list.4")}</li>
                        <li className="characteristics-item false">{getText("pages.tariffs.card_list.5")}</li>
                        <li className="characteristics-item false">{getText("pages.tariffs.card_list.6")}</li>
                        <li className="characteristics-item false">{getText("pages.tariffs.card_list.7")}</li>
                    </ul>
                    <button className="button">{getText("select")}</button>
                </div>
                <div className="tariff-card gradient">
                    <span className="title">BUSINESS</span>
                    <span className="price custom">{getText("pages.tariffs.card_title.2")}</span>
                    <ul className="characteristics-list">
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.1")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.2")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.3")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.4")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.5")}</li>
                        <li className="characteristics-item false">{getText("pages.tariffs.card_list.6")}</li>
                        <li className="characteristics-item false">{getText("pages.tariffs.card_list.7")}</li>
                    </ul>
                    <button className="button">{getText("select")}</button>
                </div>
                <div className="tariff-card gradient">
                    <span className="title">PREMIUM</span>
                    <span className="price custom">{getText("pages.tariffs.card_title.3")}</span>
                    <ul className="characteristics-list">
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.1")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.2")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.3")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.4")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.5")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.6")}</li>
                        <li className="characteristics-item true">{getText("pages.tariffs.card_list.7")}</li>
                    </ul>
                    <button className="button">{getText("select")}</button>
                </div>
            </div>
        </div>
    )
}

export default Tariffs