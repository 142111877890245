import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import AboutUs from "../components/pages/AboutUs/AboutUs";
import Portfolio from "../components/pages/Portfolio/Portfolio";
import Videos from "../components/pages/Videos/Videos";
import Tariffs from "../components/pages/Tarriffs/Tariffs";
import Contact from "../components/pages/Contact/Contact";
import PortfolioDelivery from "../components/pages/Portfolio/PortfolioDelivery/PortfolioDelivery";
import PortfolioSeller from "../components/pages/Portfolio/PortfolioSeller/PortfolioSeller";
import PortfolioAdmin from "../components/pages/Portfolio/PortfolioAdmin/PortfolioAdmin";
import VideosAdmin from '../components/pages/Videos/videosAdmin/VideosAdmin';
import VideosDelivery from '../components/pages/Videos/videosDelivery/VideosDelivery';
import VideosSeller from "../components/pages/Videos/videosSeller/VideosSeller";

export default function getRoutes() {
    return (
        <React.Fragment>
            <Switch>
                <Redirect exact from="/" to="/about_me"/>

                {/*==============about_me===========================*/}

                <Route
                    exact
                    path='/about_me'
                    component={AboutUs}
                />
                {/*==============portfolio===========================*/}

                <Route
                    exact
                    path='/portfolio'
                    component={Portfolio}
                />
                <Route
                    exact
                    path='/portfolio/admin'
                    component={PortfolioAdmin}
                />
                <Route
                    exact
                    path='/portfolio/sale'
                    component={PortfolioSeller}
                />
                <Route
                    exact
                    path='/portfolio/delivery'
                    component={PortfolioDelivery}
                />
                {/*==============videos===========================*/}

                <Route
                    exact
                    path='/videos'
                    component={Videos}
                />
                <Route
                    exact
                    path='/videos/admin'
                    component={VideosAdmin}
                />
                <Route
                    exact
                    path='/videos/sale'
                    component={VideosSeller}
                />
                <Route
                    exact
                    path='/videos/delivery'
                    component={VideosDelivery}
                />
                {/*==============tariffs===========================*/}

                <Route
                    exact
                    path='/tariffs'
                    component={Tariffs}
                />
                {/*==============contact===========================*/}

                <Route
                    exact
                    path='/contact'
                    component={Contact}
                />
            </Switch>
        </React.Fragment>
    )
}