import React from "react"

export default {
    about_us: {
        description: {
            ru: "KavApp - многофункциональная и понятная система для автоматизации кафе, миникавьярень, а также автокофеен. Наша система даст вам возможность за невысокую цену настроить и организовать рабочий процесс в своем бизнесе.",
            en: "KavApp is a multifunctional and intuitive system for automating coffee shops, mini-cafes and car cafes. Our system will give you the opportunity to set up and organize the workflow in your business at a low price.",
            ua: "KavApp – багатофункціональна та зрозуміла система для автоматизації кав’ярень, мінікав’ярень, а також автокав’ярень. Наша система дасть вам можливість за невисоку ціну налаштувати та організувати робочий процес у своєму бізнесі.",
        },
        subtitle: {
            ru: "KavApp - легко, доступно, выгодно!",
            en: "KavApp - easy, affordable, profitable!",
            ua: "KavApp – легко, доступно, вигідно!",
        },
        advantages: {
            low_price: {
                ru: "Невысокая цена по сравнению с другими конкурентами и выбор оптимального тарифа для вашего заведения",
                en: "Low price compared to other competitors and choosing the optimal rate for your establishment",
                ua: "Невисока ціна в порівнянні з іншими конкурентами та вибір оптимального тарифу для вашого закладу",
            },
            pppo: {
                ru: "Поддержка прро - решит задачу соответствия вашего бизнеса в сфере торговли с законодательными требованиями",
                en: "PRRO support - will solve the problem of compliance of your business in the field of trade with legal requirements",
                ua: "Підтримка пРРО – вирішить завдання відповідності вашого бізнесу у сфері торгівлі до законодавчих вимог",
            },
            tech_card: {
                ru: "Создание технологических карт в неограниченном количестве",
                en: "Creation of technological cards in unlimited quantities",
                ua: "Створення технологічних карток в необмеженій кількості ",
            },
            wage: {
                ru: "Расчет зарплаты, начисления штрафов, премий, авансов",
                en: "Salary calculation, accrual of fines, bonuses, advances",
                ua: "Розрахунок зарплати, нарахування штрафів, премій, авансів",
            },
            control: {
                ru: "Контроль работы бариста, логиста и движение товара на складе",
                en: "Control of the barista, logistician and movement of goods in the warehouse",
                ua: "Контроль роботи бариста, логіста та рух товару на складі",
            },
            rediscount: {
                ru: "Доступен переучет на складе и торговой точке",
                en: "Recalculation is available in the warehouse and outlet",
                ua: "Доступний переоблік на складі та торговій точці",
            },
            auto_order: {
                ru: "Функция Автозаказу - недостаток товара будет сообщать о себе сама",
                en: "Auto order function - lack of goods will report itself",
                ua: "Функція автозамовлення - нестача товару буде повідомляти про себе сама",
            },
            sale_from_warehouse: {
                ru: "Продажи сразу со склада, а не точек продаж",
                en: "Sales immediately from the warehouse, not outlets",
                ua: "Продажі відразу зі складу, а не точок продажу",
            },
            charts: {
                ru: "Большое количество графиков и отчетов для контроля вашего бизнеса",
                en: "Lots of charts and reports to monitor your business",
                ua: "Велика кількість графіків та звітів, для контролю вашого бізнесу",
            },
            work_without_internet: {
                ru: "Возможность начинать смену при отсутствии интернета",
                en: "Ability to start a change without the Internet",
                ua: "Можливість розпочинати зміну за відсутністю інтернету",
            },
            messenger: {
                ru: "Мессенджер - внутренняя переписка сотрудников в рабочее время, не отвлекая их от процесса работы",
                en: "Messenger - internal correspondence of employees during working hours, without distracting them from the work process",
                ua: "Месенджер – внутрішнє листування працівників в робочий час, не відволікаючи їх від процесу роботи",
            },
            cooking: {
                ru: "Приготовление блюд по рецепту из ингредиентов или полуфабрикаты и их продажа на кафе",
                en: "Cooking recipes from ingredients or semi-finished products and selling them in a coffee shop",
                ua: "Приготування страв по рецепту з інгредієнтів чи напівфабрикати та їх продаж на кав’ярні",
            },
            device: {
                ru: "Не эпилятор для программы. Система KavApp поддерживает любые Какие устройства - Android или IOS",
                en: "Not a device for the program. KavApp system supports any device - Android or IOS",
                ua: "Не пристрій для програми. Система KavApp підтримує будь -які пристрої - Android або IOS",
            },
        }
    },
    tariffs: {
        card_title: {
            1: {
                ua: "Безкоштовно",
                ru: "Бесплатно",
                en: "Free"
            },
            2: {
                ua: <React.Fragment><span><strong>99</strong> грн/місяць</span> за торгову точку</React.Fragment>,
                ru: <React.Fragment><span><strong>99</strong> грн/месяц</span> за торговую точку</React.Fragment>,
                en: <React.Fragment><span><strong>99</strong> UAH/month</span> for the outlet</React.Fragment>
            },
            3: {
                ua: <React.Fragment><span><strong>199</strong> грн/місяць</span> за торгову точку</React.Fragment>,
                ru: <React.Fragment><span><strong>199</strong> грн/месяц</span> за торговую точку</React.Fragment>,
                en: <React.Fragment><span><strong>199</strong> UAH/month</span> for the outlet</React.Fragment>
            }
        },
        card_list: {
            1: {
                ua: "Максимум дві торгових точки",
                en: "Maximum two outlets",
                ru: "Максимум две торговые точки"
            },
            2: {
                ua: "Програма для продаж",
                en: "Program for sale",
                ru: "Программа для продаж"
            },
            3: {
                ua: "Зарплата, аванси, штрафи",
                en: "Salary, advances, fines",
                ru: "Зарплата, авансы, штрафы"
            },
            4: {
                ua: "Склад і логістика",
                en: "Warehouse and logistics",
                ru: "Склад и логистика"
            },
            5: {
                ua: "Принтер чеків",
                en: "Check printer",
                ru: "Принтер чеков"
            },
            6: {
                ua: "Дисконтна система",
                en: "Discount system",
                ru: "Дисконтная система"
            },
            7: {
                ua: "Конструктор акцій",
                en: "Stock designer",
                ru: "Конструктор акций"
            },
        }
    },
    portfolio_delivery: {
        p: {
            en: (<React.Fragment>
                <strong>Kavapp Delivery</strong> will provide information between the warehouse and the cafe.
                <br/><br/>
                Kavapp Delivery организует командную работу в вашем заведении.
                <br/><br/>
                <ul>
                    <li>
                        Kavapp Delivery is designed to control and collaborate with the coffee shop and warehouse
                    </li>
                    <li>
                        The logistician, when starting the program, first of all sees urgent orders, which makes it work
                        relevant
                    </li>
                    <li>
                        There is access to view the availability of goods at points and warehouses and the possibility
                        of recalculation
                    </li>
                    <li>
                        View open changes, the ability to see if the barista is on a break
                    </li>
                    <li>
                        You can activate the "auto order" function. The logistician will be able to create the order
                        himself
                    </li>
                    <li>
                        Possible sales from the warehouse are quite easy and accessible and are fixed in the personal
                        account
                    </li>
                    <li>
                        In case of spoilage of a certain product, the logistician can fix it and write off the goods
                    </li>
                    <li>
                        Posting of goods to the warehouse, data recording: price, quantity
                    </li>
                    <li>
                        Through Kavapp Delivery you can also do a recalculation, where along with the calculated number
                        of goods
                        at the point of sale you need to enter the actual
                    </li>
                    <li>
                        Making collections, at the request of a logistician or administrator, to the point of sale.
                    </li>
                </ul>
            </React.Fragment>),
            ua: (
                <React.Fragment>
                    <strong>Kavapp Delivery</strong> will provide information between the warehouse and the cafe.
                    <br/><br/>
                    Kavapp Delivery организует командную работу в вашем заведении.
                    <br/><br/>
                    <ul>
                        <li>
                            Kavapp Delivery створений для контролю та співпраці кав’ярні та складу
                        </li>
                        <li>
                            Логіст, при запуску програми, першочергово бачить термінові замовлення, що робить його
                            роботу
                            актуальною
                        </li>
                        <li>
                            Є доступ до перегляду наявності товару на точках та складі та можливість здійснення
                            переобліку
                        </li>
                        <li>
                            Перегляд відкритих змін, можливість бачити чи не знаходиться бариста на перерві
                        </li>
                        <li>
                            Ви можете активізувати функцію «автозамовлення» логіст зможе самостійно створювати
                            замовлення
                        </li>
                        <li>
                            Можливі продажі зі складу – це досить легко і доступно та фіксується в особистому кабінеті
                        </li>
                        <li>
                            При зіпсуванні певного продукту, логіст може зафіксувати це та провести списання товару
                        </li>
                        <li>
                            Оприбуткування товару на склад, фіксація даних: ціна, кількість
                        </li>
                        <li>
                            Через Kavapp Delivery також можна робити переоблік, де поряд з розрахованою кількістю
                            товарів
                            на торговій точці потрібно ввести фактичну
                        </li>
                        <li>
                            Здійснення інкасацій, через запит віл логіста або адміністратора, на точку продажу.
                        </li>
                    </ul>
                </React.Fragment>
            ),
            ru: (<React.Fragment>
                <strong>Kavapp Delivery</strong> will provide information between the warehouse and the cafe.
                <br/><br/>
                Kavapp Delivery организует командную работу в вашем заведении.
                <br/><br/>
                <ul>
                    <li>
                        Kavapp Delivery создан для контроля и сотрудничества кафе и состава
                    </ li>
                    <li>
                        Логист, при запуске программы, в первую очередь видит срочные заказы, что делает его работу
                        актуальной
                    </ li>
                    <li>
                        Есть доступ к просмотру наличии товара на точках и составе и возможность осуществления переучета
                    </ li>
                    <li>
                        Просмотр открытых изменений, возможность видеть не находится бариста на перемене
                    </ li>
                    <li>
                        Вы можете активизировать функцию «Автозаказ» логист сможет самостоятельно создавать заказ
                    </ li>
                    <li>
                        Возможны продажи со склада - это довольно легко и доступно и фиксируется в личном кабинете
                    </ li>
                    <li>
                        При повреждения определенного продукта, логист может зафиксировать это и провести списание
                        товара
                    </ li>
                    <li>
                        Оприходования товара на склад, фиксация данных: цена, количество
                    </ li>
                    <li>
                        Через Kavapp Delivery можно делать переучет, где наряду с рассчитанным количеством товаров
                        на торговой точке нужно ввести фактическую
                    </ li>
                    <li>
                        Осуществление инкассаций, через запрос ВИЧ логиста или администратора, на точку продажи.
                    </ li>
                </ul>
            </React.Fragment>),
        }
    },
    portfolio_seller: {
        p: {
            en: (<React.Fragment>
                <strong> Kavapp Seller </strong> for people who sell atmosphere - a barista.
                <br/> <br/>
                The Kavapp Seller program will solve classic barista problems.
                <br/> <br/>
                <ul>
                    <li>
                        Installed in person at the coffee shop
                    </li>
                    <li>
                        Convenient for monitoring the work of the barista
                    </li>
                    <li>
                        The working time of the barista is controlled (opening and closing of change, break)
                    </li>
                    <li>
                        The menu is made of the main groups in which there are subgroups that reduces time for search of
                        the necessary position
                    </li>
                    <li>
                        Warm-up - the amount that is set and transferred from one shift to another
                    </li>
                    <li>
                        Convenient and fast customer calculation
                    </li>
                    <li>
                        View detailed pre-sales information to check for high flow
                        customers
                    </li>
                    <li>
                        All data of the barista's work are stored on the server in complete security and further work
                        with them
                        takes place in the personal account
                    </li>
                    <li>
                        Create an order for a logistician who in turn sees these messages and can see them
                        perform
                    </li>
                    <li>
                        It is possible to postpone checks, so that the client can pay in a convenient for him
                        time, and the employee remembered the uncalculated order
                    </li>
                    <li>
                        Cooking function - the ability to sell culinary or confectionery products
                        at the coffee shop.
                    </li>
                </ul>
            </React.Fragment>),
            ua: (<React.Fragment>
                <strong>Kavapp Seller</strong> для людей які продають атмосферу – бариста .
                <br/><br/>
                Програма Kavapp Seller розв’яже класичні проблеми в роботі баристи.
                <br/><br/>
                <ul>
                    <li>
                        Встановлюється особисто в кав’ярні
                    </li>
                    <li>
                        Зручний для контролю роботи бариста
                    </li>
                    <li>
                        Контролюється час роботи бариста (відкриття та закриття зміни, перерва)
                    </li>
                    <li>
                        Меню, зроблене з основних груп в яких є підгрупи, що зменшує час на пошук потрібної позиції
                    </li>
                    <li>
                        Розмінка - сума яка встановлюється та переходить з однієї зміни на іншу
                    </li>
                    <li>
                        Зручний та швидкий розрахунок клієнта
                    </li>
                    <li>
                        Перегляд детальної інформації попередніх продажів, для перевірки при великому потоці
                        клієнтів
                    </li>
                    <li>
                        Всі дані роботи баристи зберігаються на сервері в повній безпеці та подальша робота з ними
                        відбувається в особистому кабінеті
                    </li>
                    <li>
                        Створення замовлення для логіста, який своєю чергою бачить ці повідомлення та може їх
                        виконувати
                    </li>
                    <li>
                        Є можливість відкладати чеки, для того, щоб клієнт міг розрахуватися в зручний для нього
                        час, та працівник пам’ятав про не розраховане замовлення
                    </li>
                    <li>
                        Функція приготування – можливість продажу кулінарних або кондитерських виробів виготовлених
                        на кав’ярні.
                    </li>
                </ul>
            </React.Fragment>),
            ru: (<React.Fragment>
                <strong> Kavapp Seller </strong> для людей которые продают атмосферу - бариста.
                <br/> <br/>
                Программа Kavapp Seller решит классические проблемы в работе бариста.
                <br/> <br/>
                <ul>
                    <li>
                        Устанавливается лично в кафе
                    </ li>
                    <li>
                        Удобный для контроля работы бариста
                    </ li>
                    <li>
                        Контролируется время работы бариста (открытие и закрытие смены, перерыв)
                    </ li>
                    <li>
                        Меню сделано из основных групп в которых подгруппы, уменьшает время на поиск нужной позиции
                    </ li>
                    <li>
                        Розминка - сумма которая устанавливается и переходит из одной смены в другую
                    </ li>
                    <li>
                        Удобный и быстрый расчет клиента
                    </ li>
                    <li>
                        Просмотр детальной информации предыдущих продаж, для проверки при большом потоке
                        клиентов
                    </ li>
                    <li>
                        Все данные работы бариста хранятся на сервере в полной безопасности и дальнейшая работа с ними
                        происходит в личном кабинете
                    </ li>
                    <li>
                        Создание заказа для логиста, который в свою очередь видит эти сообщения и может их
                        выполнять
                    </ li>
                    <li>
                        Есть возможность откладывать чеки, для того, чтобы клиент мог рассчитаться в удобное для него
                        время, и работник помнил о не рассчитано заказ
                    </ li>
                    <li>
                        Функция приготовления - возможность продажи кулинарных или кондитерских изделий изготовленных
                        на кафе.
                    </ li>
                </ ul>
            </React.Fragment>),
        }
    },
    portfolio_admin: {
        p: {
            en: (<React.Fragment><strong>The personal account</strong> will make the administrator's work more
                efficient.
                <br/> <br/>
                Big boss of the whole system. Through your personal account, you manage Kavapp Seller and Kavapp
                Delivery - that is,
                everything is created in this office, and only here you observe the workflow and have access
                to detailed information about your business.
                <br/> <br/>
                You can involve other employees in the management of this office - this is your administrator, assistant
                etc.
                <ul>
                    <li>
                        Regardless of the number of points of sale, you have the opportunity to see detailed information
                        about each individually
                    </li>
                    <li>
                        Add an unlimited number of groups and subgroups to the menu to simplify finding items in
                        work baristas
                    </li>
                    <li>
                        Control of goods movement in the warehouse and coffee shop
                    </li>
                    <li>
                        Creation of technological cards in unlimited quantities. You record implementation information
                        technological processes - specify the ingredients, weight and method of preparation.
                    </li>
                    <li>
                        Automatic calculation of salary, issuance of advances, fines or bonuses
                    </li>
                    <li>
                        Lots of different reports to monitor your business. You will be able to see
                        time of opening and closing of the shift, sale of a certain drink at a specific time, or a
                        general report
                        for another period of time
                    </li>
                    <li>
                        Statistics will help you see the strengths and weaknesses of your entire business and save
                        from defeat
                    </li>
                    <li>
                        Discount system - a discount for your regular customers
                    </li>
                    <li>
                        Promotions allow you to create any discounts in real time
                    </li>
                </ul>
            </React.Fragment>),
            ru: (<React.Fragment><strong>Личный кабинет</strong> сделает работу администратора эффективной.<br/><br/>
                Big boss всей системы. Через личный кабинет вы управляете Kavapp Seller и Kavapp Delivery - то есть,
                все создается в этом кабинете, и только здесь вы наблюдаете за рабочим процессом и видите
                к детальной информации о вашем бизнесе.
                <br/><br/>
                К управлению из этого кабинета можно привлекать других работников - это ваш администратор, помощник
                тому подобное.
                <ul>
                    <li>
                        Несмотря на количество точек продаж, вы можете увидеть подробную информацию
                        о каждом индивидуально
                    </li>
                    <li>
                        Добавление неограниченного количества групп и подгрупп в меню, чтобы упростить поиска позиций в
                        работе бариста
                    </li>
                    <li>
                        Контроль движения товара на складе и кафе
                    </li>
                    <li>
                        Создание технологических карт в неограниченном количестве. Вы фиксируете сведения об
                        осуществлении
                        технологических процессов - указываете ингредиенты, вес и способ приготовления.
                    </li>
                    <li>
                        Автоматический подсчет зарплаты, выдача авансов, штрафов или премии
                    </li>
                    <li>
                        Большое количество разнообразных отчетов для контроля вашего бизнеса. Вы сможете увидеть
                        время открытия и закрытия смены, продажа определенного напитка в конкретное время, или же общий
                        отчет
                        другого промежуток времени
                    </li>
                    <li>
                        Статистика поможет вам увидеть слабые и сильные стороны всего вашего бизнеса и сохранит
                        от поражения
                    </li>
                    <li>
                        Дисконтная система - скидка, для ваших постоянных клиентов
                    </li>
                    <li>
                        Акции дают возможность создавать любые скидки в реальном времени
                    </li>
                </ul>
            </React.Fragment>),
            ua: (
                <React.Fragment><strong>Особистий кабінет</strong> зробить роботу адміністратора ефективнішою.<br/><br/>
                    Big boss всієї системи. Через особистий кабінет ви керуєте Kavapp Seller та Kavapp Delivery – тобто,
                    все створюється в цьому кабінеті, та лише тут ви спостерігаєте за робочим процесом та маєте доступ
                    до детальної інформації про ваш бізнес.
                    <br/><br/>
                    До керування з цього кабінету можна долучати інших працівників – це ваш адміністратор, помічник
                    тощо.
                    <ul>
                        <li>
                            Не дивлячись на кількість точок продажу, ви маєте можливість побачити детальну інформацію
                            про кожну індивідуально
                        </li>
                        <li>
                            Додавання необмеженої кількості груп та підгруп в меню, щоб спростити пошуку позицій у
                            роботі баристи
                        </li>
                        <li>
                            Контроль руху товару на складі та кав’ярні
                        </li>
                        <li>
                            Створення технологічних карток в необмеженій кількості. Ви фіксуєте відомості про здійснення
                            технологічних процесів - вказуєте інгредієнти, вагу та спосіб приготування.
                        </li>
                        <li>
                            Автоматичний підрахунок зарплатні, видача авансів, штрафів або премії
                        </li>
                        <li>
                            Велика кількість різноманітних звітів, для контролю вашого бізнесу. Ви зможете побачити
                            час відкриття та закриття зміни, продаж певного напою в конкретний час, або ж загальний звіт
                            за інший проміжок часу
                        </li>
                        <li>
                            Статистика допоможе вам побачити слабкі та сильні сторони всього вашого бізнесу та збереже
                            від поразки
                        </li>
                        <li>
                            Дисконтна система – знижка, для ваших постійних клієнтів
                        </li>
                        <li>
                            Акції дають можливість створювати будь-які знижки в реальному часі
                        </li>
                    </ul>
                </React.Fragment>),
        }
    },
    videos_admin: {
        p: {
            ua: (<React.Fragment>
                Призначений для керування з будь-якої точки на мапі та будь-якого мобільного-пристрою. В системі ви
                вказуєте параметри, обираючи функції які вам цікаві та будуть активні у вашому бізнесі та закладі. Також
                Kavapp Admin дає вам можливість створювати велику кількість торгових точок.
                Після запуску програми ви можете зазирнути в кожен куточок вашого бізнесу: додавати позиції,
                контролювати роботу баристи, проводити аналітику всього бізнесу та інше.
            </React.Fragment>),
            ru: (<React.Fragment>
                Предназначен для управления из любой точки на карте и любого мобильного устройства. В системе вы
                указываете параметры, выбирая функции которые вам интересны и будут активны в вашем бизнесе и заведении.
                также
                Kavapp Admin дает вам возможность создавать большое количество торговых точек.
                После запуска программы вы можете заглянуть в каждый уголок вашего бизнеса: добавлять позиции,
                контролировать работу бариста, проводить аналитику всего бизнеса и прочее.
            </React.Fragment>),
            en: (<React.Fragment>
                Designed to control from anywhere on the map and any mobile device. You are in the system
                specify the parameters, choosing the functions that interest you and will be active in your business and
                institution. Also
                Kavapp Admin gives you the opportunity to create a large number of outlets.
                After launching the program, you can look in every corner of your business: add positions,
                monitor the work of baristas, conduct analytics of the entire business and more.
            </React.Fragment>),
        }
    },
    videos_delivery: {
        p: {
            ua: (<React.Fragment>
                Призначений для контролю руху товарів від складу до кав’ярні. Логіст повноцінно працює на складі:
                оприбутковує товар, виконує переоблік, стежить за терміном придатності та в разі необхідності проводить
                списання та інше. В комплексі роботи всієї системи зі складом, за допомогою програми Kavapp Delivery
                фіксуємо кількість привезеного товару на кав’ярню, знаємо кількість товару які пішли на приготування
                напоїв – відповідно можемо бачити залишок товару на кав’ярні в режимі реального часу.
            </React.Fragment>),
            ru: (<React.Fragment>
                Предназначен для контроля движения товаров от состава в кафе. Логист полноценно работает на складе:
                оприходует товар, выполняет переучет, следит за сроком годности и в случае необходимости проводит
                списание и прочее. В комплексе работы всей системы с составом, с помощью программы Kavapp Delivery
                фиксируем количество привезенного товара на кафе, знаем количество товара которые пошли на приготовление
                напитков - соответственно можем видеть остаток товара на кафе в режиме реального времени.
            </React.Fragment>),
            en: (<React.Fragment>
                Designed to control the movement of goods from the warehouse to the cafe. The logistician fully works in
                the warehouse: posts the goods, recalculates, monitors the expiration date and, if necessary, writes off
                and more. In the complex work of the whole system with the warehouse, with the help of Kavapp Delivery
                we record the amount of goods brought to the cafe, we know the number of goods that went to prepare
                drinks - accordingly we can see the rest of the goods in the cafe in real time.
            </React.Fragment>),
        }
    },
    videos_seller: {
        p: {
            ua: (<React.Fragment>
                Зручний у використанні для баристи Kavapp Seller фіксує час роботи працівника, через функції відкриття
                та закриття зміни. Через додаток, працівник кав’ярні має можливість робити замовлення для логіста та
                фіксувати привезений товар у своєму додатку на планшеті. Також є можливість перегляду детальної
                інформації по всіх здійснених продажах на поточній зміні. Доступний розрахунок готівкою, терміналом та
                знижкою налаштованою вашою дисконтною системою.
                Всі дані бариста зберігаються на сервері. Подальша робота з ними відбувається в особистому кабінеті.
            </React.Fragment>),
            ru: (<React.Fragment>
                Удобный в использовании для бариста Kavapp Seller фиксирует время работы работника, за функции открытия
                и закрытия смены. Через приложение, работник кафе имеет возможность делать заказы для логиста и
                фиксировать привезенный товар в своем приложении на планшете. Также есть возможность просмотра детальной
                информации по всем осуществленных продажах на текущей смене. Доступен наличный расчет, терминалом и
                скидкой настроенной вашей дисконтной системой.
                Все данные бариста хранятся на сервере. Дальнейшая работа с ними происходит в личном кабинете.
            </React.Fragment>),
            en: (<React.Fragment>
                Easy to use for barista Kavapp Seller captures the working time of the employee, through the opening functions
                and closing the shift. Through the app, the coffee shop employee has the opportunity to place orders for the logistician and
                to fix the brought goods in the application on the tablet. It is also possible to view the detailed
                information on all sales made on the current shift. Available payment in cash, terminal and
                discount set up by your discount system.
                All barista data is stored on the server. Further work with them takes place in the personal account.
            </React.Fragment>),
        }
    }
}
