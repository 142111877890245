import Slider from "../../pageComponents/Slider/Slider";
import "./contact.scss"
import getText from "../../../appText/getText";
import gmail from "../../../img/icons/gmail.svg";
import facebook from "../../../img/icons/facebook.svg";
import instagram from "../../../img/icons/instagram.svg";
import telegram from "../../../img/icons/telegram.svg";
import viber from "../../../img/icons/viber.svg";

function Contact() {
   function getSlides() {
      const slides = []
      for (let i = 0; i < 3; i++) {
         slides.push(<div className="slide"><h3>{i + 1}</h3></div>)
      }
      return slides
   }

   return (
      <div className="contact">
         <Slider slides={getSlides()}/>
         <h2>{getText("contact")}</h2>
         <div className="contact-block gradient">
            <div className="social-networks">
               <ul className="social-networks-list">
                  <li>
                     <img src={gmail} alt="#"/>
                     <span>info@kavapp.com</span>
                  </li>
                  <li>
                     <img src={facebook} alt="#"/>
                     <span>info@kavapp.com</span>
                  </li>
                  <li>
                     <img src={instagram} alt="#"/>
                     <span>info@kavapp.com</span>
                  </li>
                  <li>
                     <img src={telegram} alt="#"/>
                     <span>info@kavapp.com</span>
                  </li>
                  <li>
                     <img src={viber} alt="#"/>
                     <span>info@kavapp.com</span>
                  </li>
               </ul>
            </div>
            <div className="contact-form">
               <form>
                  <input type="text" placeholder={getText("name")}/>
                  <input type="text" placeholder={getText("surname")}/>
                  <input type="text" placeholder={getText("mail")}/>
                  <textarea rows={6} placeholder={getText("message")}/>
                  <button type="submit">{getText("send")}</button>
               </form>
            </div>
         </div>
      </div>
   )
}

export default Contact


