export const videos = [
   {
      title: "Головний екран",
      src: "https://www.youtube.com/embed/RGF8TBRVyJI"
   },
   {
      title: "Програма з продажу",
      src: "https://www.youtube.com/embed/KxmU5B5Lh9s"
   },
   {
      title: "Головний екран",
      src: "https://www.youtube.com/embed/KxmU5B5Lh9s"
   },
   {
      title: "Головний екран",
      src: "https://www.youtube.com/embed/KxmU5B5Lh9s"
   },
   {
      title: "Головний екран",
      src: "https://www.youtube.com/embed/KxmU5B5Lh9s"
   },
   {
      title: "Головний екран",
      src: "https://www.youtube.com/embed/KxmU5B5Lh9s"
   },

]