import getText from "../../../../appText/getText"
import appStore from "./../../../../img/AppStore.svg"
import googlePlay from "./../../../../img/GooglePlay.svg"
import "./videos-admin.scss"
import Player from "../../../pageComponents/Player/Player";
import {videos} from "./videos";

function VideosAdmin() {

   return (
      <div className="videos-admin">
         <h1 className="page-title"> Kavapp Admin </h1>
         <h3 className="subtitle">{getText("programForAdmin")}</h3>
         <p>{getText("pages.videos_admin.p")}</p>
         <div className="links_group">
            <a className="link_button" href="#">
               <img src={googlePlay} alt="#"/>
            </a>
            <a className="link_button" href="#">
               <img src={appStore} alt="#"/>
            </a>
         </div>  
         <Player videosList={videos}/>
      </div>
   )
}

export default VideosAdmin