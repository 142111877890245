export default {
    portfolio: {
        ua: "Портфоліо",
        ru: "Портфолио",
        en: "Portfolio",
    },
    about_us: {
        ua: "Про нас",
        ru: "Про нас",
        en: "About us",
    },
    video: {
        ua: "Відео",
        ru: "Видео",
        en: "Video",
    },
    tariffs: {
        ua: "Тарифи",
        ru: "Тарифы",
        en: "Tariffs",
    },
    contact: {
        ua: "Зв’язок",
        ru: "Связь",
        en: "Contact",
    },
    select: {
        ua: "Обрати",
        ru: "Выбрать",
        en: "select",
    },
    name: {
        ua: "Ім'я",
        ru: "Имя",
        en: "Name",
    },
    surname: {
        ua: "Прізвище",
        ru: "Фамилия",
        en: "Surname",
    },
    mail: {
        ua: "Електронна пошта",
        ru: "Электронная почта",
        en: "Mail",
    },
    message: {
        ua: "Повідомлення",
        ru: "Сообщение",
        en: "Message",
    },
    send: {
        ua: "Надіслати",
        ru: "Отправить",
        en: "Send",
    }
}