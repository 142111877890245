import "./videos-delivery.scss"
import getText from "../../../../appText/getText";
import googlePlay from "../../../../img/GooglePlay.svg";
import appStore from "../../../../img/AppStore.svg";
import Player from "../../../pageComponents/Player/Player";
import {videos} from "../videosAdmin/videos";
import "./videos-delivery.scss"

function VideosDelivery() {
    return (
        <div className="videos-delivery">
           <h1 className="page-title"> Kavapp Delivery</h1>
           <h3 className="subtitle">{getText("programForLogisticians")}</h3>
           <p>{getText("pages.videos_delivery.p")}</p>
           <div className="links_group">
              <a className="link_button" href="#">
                 <img src={googlePlay} alt="#"/>
              </a>
              <a className="link_button" href="#">
                 <img src={appStore} alt="#"/>
              </a>
           </div>
           <Player videosList={videos}/>
        </div>
    )
}

export default VideosDelivery