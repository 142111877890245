import getText from "../../../../appText/getText";
import {useHistory} from "react-router-dom";
import "./navigation.scss"
import {useContext, useEffect, useRef, useState} from "react";
import logoLight from "../../../../img/logo-light.svg";
import logoNight from "../../../../img/logo-night.svg";
import {GlobalContext} from "../../../pages/App";

export default function Navigation() {
   const [openNavigation, setOpenNavigation] = useState(false)
   const history = useHistory()
   const pathName = history.location.pathname.substr(1, history.location.pathname.length)
   const navListRef = useRef()
   const {globalState: {theme, windowIsSmall}} = useContext(GlobalContext)

   function onMenuItemClick(path) {
      history.push("/" + path)
      windowIsSmall && setOpenNavigation(false)
   }

   function getClassNames(path) {
      return pathName.includes(path) ? "active" : ""
   }

   function renderNavBtn() {
      return (
         windowIsSmall ?
            <div
               className="open-navigation-button"
               onClick={() => setOpenNavigation(true)}
            >
               <span/>
            </div>
            : null
      )
   }

   return (
      <div className="navigation light-gradient">
         <div className="container">
            {renderNavBtn()}
            <nav ref={navListRef} className={openNavigation ? "nav-list show" : "nav-list"}>
               {
                  windowIsSmall &&
                  <div className="close-navigation-block">
                     <img src={theme === "light" ? logoLight : logoNight} alt="logo"/>
                     <div
                        className="close-navigation-button"
                        onClick={() => setOpenNavigation(false)}
                     />
                  </div>
               }
               <div
                  onClick={() => onMenuItemClick("about_me")}
                  className="nav-list-item"
               >
                  <span className={getClassNames("about_me")}>{getText("about_us")}</span>
               </div>
               <div className="nav-list-item">
                        <span
                           className={getClassNames("portfolio")}
                           onClick={() => onMenuItemClick("portfolio")}
                        >
                            {getText("portfolio")}
                        </span>
                  <div className="submenu light-bg">
                     <span onClick={() => onMenuItemClick("portfolio/admin")}>{getText("header.admin")}</span>
                     <span onClick={() => onMenuItemClick("portfolio/sale")}>{getText("header.sale")}</span>
                     <span onClick={() => onMenuItemClick("portfolio/delivery")}>{getText("header.delivery")}</span>
                  </div>
               </div>
               <div className="nav-list-item">
                        <span
                           className={getClassNames("videos")}
                           onClick={() => onMenuItemClick("videos")}
                        >
                            {getText("video")}
                        </span>
                  <div className="submenu light-bg">
                     <span onClick={() => onMenuItemClick("videos/admin")}>{getText("header.admin")}</span>
                     <span onClick={() => onMenuItemClick("videos/sale")}>{getText("header.sale")}</span>
                     <span onClick={() => onMenuItemClick("videos/delivery")}>{getText("header.delivery")}</span>
                  </div>
               </div>
               <div className="nav-list-item">
                        <span
                           className={getClassNames("tariffs")}
                           onClick={() => onMenuItemClick("tariffs")}
                        >
                            {getText("tariffs")}
                        </span>
               </div>
               <div className="nav-list-item">
                        <span
                           onClick={() => onMenuItemClick("contact")}
                           className={getClassNames("contact")}
                        >
                            {getText("contact")}
                        </span>
               </div>
            </nav>
            <button className="redirect-to-adminpanel-button gradient">
               <a href="https://admin.kavapp.com/" target="-_blank">{getText("header.private_account")}</a>
            </button>
            {
               openNavigation &&
               <div
                  className={openNavigation ? "blackout show" : "blackout"}
                  onClick={() => setOpenNavigation(false)}
               />
            }
         </div>
      </div>
   )
}