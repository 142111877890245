const componentsText = {
    programForLogisticians: {
        ua: "Програма для логістів",
        ru: "Программа для логистов",
        en: "Program for logisticians",
    },
    programForSeller: {
        ua: "Програма для баристи",
        ru: "Программа для бариста",
        en: "Program for baristas",
    },
    programForAdmin: {
        ua: "Програма для адміністратора ",
        ru: "Программа для администратора",
        en: "Program for the administrator",
    },
    header: {
        private_account: {
            ua: "Особистий кабінет",
            ru: "Личный кабинет",
            en: "Private account",
        },
        admin: {
            ua: "Для адміністратора",
            ru: "Для администратора",
            en: "For admin",
        },
        sale: {
            ua: "Для продажу",
            ru: "Для продажи",
            en: "For sale",
        },
        delivery: {
            ua: "Для логіста",
            ru: "Для логиста",
            en: "For the logistician",
        }
    },
    footer: {
        connect: {
            ua: "З нами можна зв’язатись: ",
            ru: "С нами можно связаться:",
            en: "You can contact us: ",
        },
        we_accept: {
            ua: "Ми приймаємо: ",
            ru: "Мы принимаем: ",
            en: "We accept: ",
        },
        phone: {
            ua: "Наш телефон: ",
            ru: "Наш телефон: ",
            en: "Phone: ",
        },
    },
}

export default componentsText