import componentsText from "./text/componentsText";
import words from "./text/words";
import pages from "./text/pages";

const languageData = {
    ...componentsText,
    ...words,
    pages
}

export default languageData