import Slider from "../../pageComponents/Slider/Slider";
import "./about_us.scss"
import getText from "../../../appText/getText";
import {ReactComponent as LowPrice} from "../../../img/icons/low_price.svg"
import {ReactComponent as Pppo} from "../../../img/icons/pPPO.svg"
import {ReactComponent as TechCard} from "../../../img/icons/tech_cards.svg"
import {ReactComponent as Wage} from "../../../img/icons/wage.svg"
import {ReactComponent as Control} from "../../../img/icons/control.svg"
import {ReactComponent as Rediscount} from "../../../img/icons/rediscount.svg"
import {ReactComponent as AutoOrder} from "../../../img/icons/automatic_order.svg"
import {ReactComponent as SaleFromWarehouse} from "../../../img/icons/sele_from_warehouse.svg"
import {ReactComponent as Charts} from "../../../img/icons/charts.svg"
import {ReactComponent as WorkWithoutInternet} from "../../../img/icons/work_without_internet.svg"
import {ReactComponent as Messenger} from "../../../img/icons/messenger.svg"
import {ReactComponent as Cooking} from "../../../img/icons/cooking.svg"
import {ReactComponent as Device} from "../../../img/icons/device.svg"


function AboutUs() {

    function getSlides() {
        const slides = [];
        for (let i = 0; i < 3; i++) {
            slides.push(<div key={i} className="slide"><h3>{i + 1}</h3></div>);
        }
        return slides;
    }

    return (
        <div className="about_us">
            <Slider slides={getSlides()}/>
            <h1 className="page-title">{getText("about_us")}</h1>
            <h2 className="subtitle">{getText("pages.about_us.subtitle")}</h2>
            <p className="description">{getText("pages.about_us.description")}</p>
            <ul className="advantages-list">
                <li className="advantages-list-item">
                    <LowPrice/>
                    <p>{getText("pages.about_us.advantages.low_price")}</p>
                </li>
                <li className="advantages-list-item">
                    <Pppo/>
                    <p>{getText("pages.about_us.advantages.pppo")}</p>
                </li>
                <li className="advantages-list-item">
                    <TechCard/>
                    <p>{getText("pages.about_us.advantages.tech_card")}</p>
                </li>
                <li className="advantages-list-item">
                    <Wage/>
                    <p>{getText("pages.about_us.advantages.wage")}</p>
                </li>
                <li className="advantages-list-item">
                    <Control/>
                    <p>{getText("pages.about_us.advantages.control")}</p>
                </li>
                <li className="advantages-list-item">
                    <Rediscount/>
                    <p>{getText("pages.about_us.advantages.rediscount")}</p>
                </li>
                <li className="advantages-list-item">
                    <AutoOrder/>
                    <p>{getText("pages.about_us.advantages.auto_order")}</p>
                </li>
                <li className="advantages-list-item">
                    <SaleFromWarehouse/>
                    <p>{getText("pages.about_us.advantages.sale_from_warehouse")}</p>
                </li>
                <li className="advantages-list-item">
                    <Charts/>
                    <p>{getText("pages.about_us.advantages.charts")}</p>
                </li>
                <li className="advantages-list-item">
                    <WorkWithoutInternet/>
                    <p>{getText("pages.about_us.advantages.work_without_internet")}</p>
                </li>
                <li className="advantages-list-item">
                    <Messenger/>
                    <p>{getText("pages.about_us.advantages.messenger")}</p>
                </li>
                <li className="advantages-list-item">
                    <Cooking/>
                    <p>{getText("pages.about_us.advantages.cooking")}</p>
                </li>
                <li className="advantages-list-item">
                    <Device/>
                    <p>{getText("pages.about_us.advantages.device")}</p>
                </li>
            </ul>
            <div className="categories">
                <span>Система поділяться на 3 категорії</span>
                <p>
                    -	Особистий кабінет ( Admin) – для адміністратора <br/>
                    -	Програма з продажу (Seller)  – для бариста <br/>
                    -	Склад і доставка (Delivery) – для логіста <br/>
                </p>
            </div>
        </div>
    )
}

export default AboutUs